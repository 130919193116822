import React, { useEffect, useState } from 'react';
import SEO from "../shared/seo";
import Layout from "../components/organisms/layout/layout";
import { ReferenciaContenido } from "../interfaces/ReferenciaContenido";
import LibraryComponents from "../components/templates/library-components/libraryComponents";
import Loading from "../components/atoms/loading/loading";
import { getEntryDeliveryApi } from "../shared/services/CMS.js";
import { getInsideInfo } from "../services/contentful";

const TemplateFour = (props: any) => {

   const [pageContext, setPageContext] = useState<any>({})

   useEffect(() => { 
      const getPageContext = async () => { 
         let dataCont: any = await getEntryDeliveryApi(props.pageContext.contentful_id)
         dataCont.referenciaCabecera = await getInsideInfo(dataCont.referenciaCabecera)
         dataCont.referenciaContenido = await getInsideInfo(dataCont.referenciaContenido)
         dataCont.referenciaCabecera.migaDePan = props.pageContext.referenciaCabecera.migaDePan
         setPageContext(dataCont)
      } 
      getPageContext()
    }, [])

   const pageSlug = props.location.pathname

   return ( 
      <Layout 
         hideHeaderDesktop={true} 
         hideHeaderMobile={true} 
         isNotRoot={props.pageContext.noRoot !== true ? true : false} 
         headboard={pageContext.referenciaCabecera ? pageContext.referenciaCabecera : ""} 
         typeChat={props.pageContext.tipoChat} 
         menuEspecifico={props.pageContext.menuEspecifico} 
         esconderLoggin={props.pageContext.esconderLoggin} 
      > 
         <SEO
            title={props.pageContext.metaTitulo || props.pageContext.nombreCategoria}
            slug={ pageSlug }
            description={ props.pageContext.metaDescripcion && props.pageContext.metaDescripcion.json.content[0].content[0].value } 
            lang={ "es" }
            noCanonical={ props.pageContext.noCanonical }
            urlCanonical={ props.pageContext.urlCanonical }
            noIndex={ props.pageContext.noIndex }
            fbId={process.env.FACEBOOK_APP_ID_HOME}
         />
         {pageContext.referenciaContenido ? pageContext.referenciaContenido.map(
            (value: ReferenciaContenido, index: number) => (
               <LibraryComponents
                  key={index}
                  content={value}
                  categoryName={pageContext.nombreCategoria}
               />
            )
         )
         :
         <Loading />
         }
      </Layout>
   );
};

export default TemplateFour;
